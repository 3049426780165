import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Questionnaire from '../projects/Questionnaire';
import { deleteQuestionnaire } from '../../../store/actions/questionnaireActions';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { signOut } from '../../../store/actions/authActions';
import IconButton from '@material-ui/core/IconButton';
import Apps from '@material-ui/icons/Apps';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import QuestionnairesView from './QuestionnairesView';
import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, purple, blueGrey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  table: {
    // minWidth: '550px',
  },
  breadcrumbs: {
    textAlign: 'left',
    margin: theme.spacing(1)
  },
  inactive: {
    color: '#2eb85c'
  }
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(IconButton);
const theme = createMuiTheme({
  palette: {
    primary: green,
    backgroundColor: blueGrey[500],
  },
});

const ccc = (v) => {

}

const convertArrayByField = (model, field = 'docId') => {
  let converted = [];
  model && model.forEach(record => {
    let f = record[field] == undefined ? 'xxx' : record[field];
    if (!(f in converted)) {
      converted[f] = [];
    }
    converted[f].push(record);
  })

  return converted;
}
const Dashboard = ({ sampling, permissions, users, questionnaires, authStatus, userStatus, filledquestionnaires }) => {
  const { isEmpty, isLoaded } = authStatus;
  const classes = useStyles();
  const path = [{ name: 'Kérdőívek', path: '/' }];
  let a = permissions && Array.isArray(permissions) && permissions[0] && permissions[0].participant ? Object.keys(permissions[0].participant) : ['2'];
  let p = 'in';
  // if (permissions && Array.isArray(permissions) && permissions[0] && permissions[0].isAdmin) {
  //   a = '0';
  //   p = '!=';
  // }

  useFirestoreConnect([
    {
      collection: 'questionnaires',
      where: [

        // ['id', p, a],
        ['public', '==', true],
      ],
      // where: [

      //   ['id', b, '0'],
      // ],
      // orderBy: ['createdAt', 'asc']
    },
    {
      collection: "sampling",
      where: [
        ['status', '==', "1"],
      ],
      storeAs: 'sampling'
    },
    {
      collection: "filledquestionnaires",
      where: [
        ['authorId', '==', userStatus.uid || 'xxx']
      ],
      //  orderBy: ["provider_eqalm_id", "asc"],
    },
    {
      collection: "user_questionnaire_permission",
      doc: authStatus.uid,
      //    where: [
      //     ['doc', '==', authStatus.uid],
      // ],
      storeAs: 'permissions'
    },
    {
      collection: "users",
      doc: authStatus.uid,
    }

  ]);
  let filledquestionnairesArray = convertArrayByField(filledquestionnaires);
  let filledquestionnairesSamplingArray = convertArrayByField(filledquestionnaires, "samplingId");
  let samplingArray = convertArrayByField(sampling, 'name');
  let samplingArrayId = convertArrayByField(sampling, 'id');
  let filled = [];
  if (filledquestionnaires) {
    Object.keys(filledquestionnaires).forEach(key => { if (filledquestionnaires[key].authorId === userStatus.uid) { filled[filledquestionnaires[key].docId] = filledquestionnaires[key] } })
  }

  return (
    <Container maxWidth='lg'>
      {/* <ColorButton variant="contained" color="primary" className={classes.margin}><Apps /></ColorButton>
      <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary" className={classes.margin}>
          Theme Provider
        </Button>
      </ThemeProvider> */}
      {isLoaded &&
        (isEmpty ? (
          <Redirect to='/signin' />
        ) : (
          questionnaires &&
          <QuestionnairesView
            questionnaires={questionnaires}
            sampling={samplingArray}
            samplingId={samplingArrayId}
            filled={filledquestionnairesArray}
            filledSampling={filledquestionnairesSamplingArray}
            changeView={ccc}
            classes={classes} path={path}
          />

        ))}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    questionnaires: state.firestore.ordered.questionnaires,
    filledquestionnaires: state.firestore.ordered.filledquestionnaires,
    sampling: state.firestore.ordered.sampling,
    permissions: state.firestore.ordered.permissions,
    authStatus: state.firebase.auth,
    userStatus: state.firebase.auth,
    users: state.firestore.ordered.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteQuestionnaire: (id) => dispatch(deleteQuestionnaire(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
